import React, { useRef, useEffect } from 'react';
import './DesignViews.css'; // Import the styles

// Import images
import image1 from '../assets/Render.11.png';
import image2 from '../assets/design 9.png';
import image3 from '../assets/final top.png';
import image4 from '../assets/1st.png';
import image5 from '../assets/lofted winglets.png';

const DesignViews = () => {
  const scrollContainerRef = useRef(null);
  const images = [image1, image2, image3, image4, image5];

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const scrollInterval = setInterval(() => {
      if (scrollContainer) {
        const scrollWidth = scrollContainer.scrollWidth;
        const containerWidth = scrollContainer.clientWidth;
        const newScrollLeft = (scrollContainer.scrollLeft + containerWidth) % scrollWidth;

        scrollContainer.scrollTo({
          left: newScrollLeft,
          behavior: 'smooth',
        });
      }
    }, 4000); // Increased delay for eye ergonomics (scroll every 4 seconds)

    return () => clearInterval(scrollInterval);
  }, []);

  // Function to handle resizing
  useEffect(() => {
    const handleResize = () => {
      const scrollContainer = scrollContainerRef.current;
      if (scrollContainer) {
        // Adjust image sizes on resize
        const containerWidth = scrollContainer.clientWidth;
        const containerHeight = scrollContainer.clientHeight;

        // You can also add custom behavior to make image sizes adapt based on container dimensions
        scrollContainer.style.width = `${containerWidth}px`;
        scrollContainer.style.height = `${containerHeight}px`;
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call on mount

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="design-views-container">
      <div className="design-views-scroll" ref={scrollContainerRef}>
        {images.map((image, index) => (
          <div className="design-card" key={index}>
            <img src={image} alt={`Design view ${index + 1}`} className="design-image" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DesignViews;
